













































































































import { Component, Watch } from "vue-property-decorator";
import { Account } from "@/interfaces/Account";
import Loader from "@/components/Loader.vue";
import SearchBar from "@/components/dossier/SearchBar.vue";
import DjlMultiselect from "@/components/form-components/djlMultiselect.vue";
import { TranslateResult } from "vue-i18n";
import DocumentList from "@/components/dossier/DocumentList.vue";
import mFiles, { DossierDocument } from "@/api/mFiles";
import { AxiosResponse } from "axios";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

import accessControl from "@/api/access_control";
import { resolvePromiseSettledResult } from "@/utils/promises";
import djlMultiSelect from "@/components/form-components/djlMultiselect.vue";
import { accountLabel } from "@/utils/accountDisplayFormatter";

@Component({
  methods: { accountLabel },
  components: {
    djlMultiSelect,
    DocumentList,
    Loader,
    SearchBar,
    DjlMultiselect,
  },
})
@Translatable("dossier")
export default class EmployeeDossier extends TranslatableComponent {
  private isLoadingAccounts: boolean = true;
  private isLoadingDossierDocuments: boolean = false;
  private documents: DossierDocument[] = [];
  private accounts: Account[] = [];
  private filter: string = "";
  private selectedAccounts: Account[] | null = null;

  onSearchBarChange(value: string) {
    this.filter = value;
  }

  get hasDocuments(): boolean {
    return this.documents.length > 0;
  }

  get hasAccounts(): boolean {
    return this.accounts.length > 0;
  }

  $refs!: {
    accountSelector: DjlMultiselect;
  };

  mounted() {
    accessControl.accounts
      .accounts()
      .then((response: AxiosResponse<Account[]>) => {
        this.accounts = response.data;
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_api_value("access_control.error.fetch_accounts")
        );
        this.accounts = [];
      })
      .finally(() => {
        this.isLoadingAccounts = false;
      });
  }

  @Watch("selectedAccounts")
  async renderDossierDocuments(accounts: Account[]) {
    this.documents = [];

    if (accounts.length === 0) return;

    this.isLoadingDossierDocuments = true;

    const dossierDocumentsPromises: Promise<DossierDocument[]>[] = [];

    accounts.forEach((account: Account) => {
      dossierDocumentsPromises.push(this.getDossierDocuments(account));
    });

    this.documents = await Promise.allSettled(dossierDocumentsPromises)
      .then(resolvePromiseSettledResult)
      .catch(() => []);

    this.isLoadingDossierDocuments = false;
  }

  getDossierDocuments(selectedAccount: Account): Promise<DossierDocument[]> {
    return mFiles.getDocumentsWithAccount(selectedAccount).catch(() => {
      this.selectedAccounts =
        this.selectedAccounts?.filter(
          (selectedValue: any) => selectedValue !== selectedAccount
        ) || null;

      this.$snotify.error(
        this.translated_view_value("can_not_get_documents", {
          name: selectedAccount.name,
        })
      );

      return [];
    });
  }

  hasSelectedAccount(): boolean {
    return Array.isArray(this.selectedAccounts)
      ? this.selectedAccounts.length !== 0
      : false;
  }

  get noResultsMessage(): TranslateResult {
    if (this.hasDocuments && this.hasSelectedAccount()) {
      return this.translated_view_value("no_search_results");
    }

    return this.hasSelectedAccount()
      ? this.translated_view_value("no_documents_found_message")
      : "";
  }
}
