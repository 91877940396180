












































import { Component, Prop } from "vue-property-decorator";

import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import DefaultTable, {
  TableHeaders,
} from "@/components/tables/DefaultTable.vue";
import { filteredData } from "@/components/tables/filter";
import { orderBy } from "lodash";
import { formatDateToDateString } from "@/filters";
import { DossierDocument } from "@/api/mFiles";

@Component({
  components: { DefaultTable },
  methods: { formatDateToDateString },
})
@Translatable("")
export default class DocumentList extends mixins(TranslatableComponent) {
  @Prop()
  private documents!: DossierDocument[];

  @Prop()
  private extraFilters!: ((o: DossierDocument) => boolean)[];

  @Prop()
  private filter!: string;

  private headers: TableHeaders<DossierDocument>[] = [
    {
      property: "name",
      name: "Dossierstuk",
    },
    {
      property: "account.name",
      name: "Entiteit",
    },
    {
      property: "className",
      name: "Klasse",
      class: "hidden-tablet-down",
    },
    {
      property: "year",
      name: "Jaar",
    },
    {
      property: "lastModified",
      name: "Gewijzigd op",
      class: "hidden-tablet-down",
    },
  ];

  get filteredDocuments(): DossierDocument[] {
    return orderBy(
      filteredData(this.documents, this.filters),
      "lastModified",
      "desc"
    );
  }

  private breakWordIfDescriptionTooLong(description: string) {
    const individualDescriptionWordIsTooLong = description
      .split(/-| /)
      .some((part) => part.length > 40);
    return {
      "word-break": individualDescriptionWordIsTooLong ? "break-all" : "normal",
    };
  }

  get computedFilter() {
    return this.filter;
  }

  get filters(): ((o: DossierDocument) => boolean)[] {
    return [
      ...[
        (o: DossierDocument) => {
          const filterKeys: Array<keyof typeof o> = [
            "name",
            "year",
            "className",
          ];
          return filterKeys.some((key) => {
            if (o[key]) {
              return o[key]
                .toString()
                .toLowerCase()
                .includes(this.computedFilter.toLowerCase());
            }

            return false;
          });
        },
      ],
      ...this.extraFilters,
    ];
  }

  private onTdClicked(document: DossierDocument) {
    this.$router.push({
      name: "dossier-document",
      params: {
        accountId: document.account.id,
        documentId: document.id.toString(),
        vault: document.vault,
      },
    });
  }
}
